<template>
    <div class="pagebg">
        <Slider ratio="5by2" animation="fade" :slides="slides" class="slider--gradient">
            <template v-slot:slide="{ slide }">
                <div class="c w-xxl flex hide__xs">
                    <h1 class="m-0 col-8 col-6__m-up col-4__l-up pt-l color-white">
                        {{ slide.title }}<span class="color-accent">.</span>
                    </h1>
                </div>
            </template>
        </Slider>

        <div class="c w-xxl">
            <div class="flex -justify-center centeredtext--home">
                <CenteredText :title="texts['about_title_' + lang]" :text="texts['about_text_' + lang]" :link="{
                    title: $t('about.more'),
                    to: { name: 'about' },
                    class: 'bg-white',
                    icon: 'chevron-right'
                }" />
            </div>
        </div>

        <div class="products__home section-margin-top">
            <HorizontalContentSlider v-if="showcaseImages.length > 0" :items="showcaseImages">
                <template v-slot:item="{ item, index }">
                    <img @click="onHorizontalImagesClick(index)" class="rounded cursor-pointer" :src="item"
                        loading="lazy" />
                </template>
            </HorizontalContentSlider>

            <div class="c w-xxl home__products mt-xl">
                <div class="flex -wrap columns color-secondary -align-stretch">
                    <div class="col-12 col-6__s-up col-4__l-up pb-0">
                        <h2 class="color-primary" v-text="texts['products_title_' + lang]" />
                    </div>
                    <div class="col-12 col-6__s-up col-4__l-up pb-0">
                        <div class="mt-r">
                            {{ texts['products_text_' + lang] }}<br /><br />
                            <router-link class="bg-white -s button" :to="{ name: 'products' }">{{ $t('menu.products') }}
                                <icon class="mdi mdi-chevron-right" />
                            </router-link>
                        </div>
                    </div>
                    <div class="col-12 col-6__s-up col-4__l-up pb-0" v-if="highlights.length">
                        <Highlight :title="highlights[0]['title_' + lang]" :text="highlights[0]['text_' + lang]" />
                    </div>
                </div>
            </div>
        </div>

        <div class="video bg-white ratio-3by1 section-margin-top" v-if="texts.home_video">
            <VideoBG :src="texts.home_video" />
        </div>

        <div class="c w-xxl pv-xxl">

            <div class="home__news flex columns mt-xxl pv-xxl -align-stretch -justify-center -wrap">
                <div class="col-12 col-3__l-up text-right flex -column -align-end pb-0">
                    <h1 class="mt-0" v-html="$t('news.title').split(' ').join('<br />')"></h1>
                    <span class="-flex-grow"></span>

                    <Newsletter :translations="{
                        email: $t('newsletter.email'),
                        subscribe: $t('newsletter.subscribe'),
                    }" :formId="settings.enormail_id" buttonClass="bg-white -icon -s" inputClass="-s"
                        v-if="settings.enormail_id">
                        <template v-slot:default_header>
                            <div class="text-uppercase weight-b color-secondary">
                                {{ $t('newsletter.title') }}:
                            </div>
                        </template>
                        <template v-slot:success>
                            <div class="text-right color-success size-6">
                                {{ $t('newsletter.success') }}
                            </div>
                        </template>
                        <template v-slot:buttonContent>
                            <icon class="mdi mdi-chevron-right" />
                        </template>
                    </Newsletter>
                </div>

                <div class="col-12 col-7__l-up pb-0">
                    <NewsArticles :amount="2" articleClass="col-12 col-6__s-up mb-xxl mb-0__m-up pb-0" />
                </div>

                <router-link :to="{ name: 'news' }" class="more__news">
                    <icon class="mdi mdi-chevron-right size-1" />
                </router-link>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    name: 'Home',
    components: {
        CenteredText: () => import('@/components/CenteredText.vue'),
        Highlight: () => import('@/components/Highlight.vue'),
        HorizontalContentSlider: () => import('@/components/ui/HorizontalContentSlider.vue'),
        Newsletter: () => import('@/components/ui/Newsletter/Enormail.vue'),
        NewsArticles: () => import('@/components/NewsArticles.vue'),
        Slider: () => import('@/components/ui/Slider.vue'),
        VideoBG: () => import('@/components/ui/VideoBG.vue'),
    },

    computed: {
        showcaseImages() {
            const images = [];
            for (let i = 0; i < 12; i += 1) {
                if (this.texts[`products_image${i}`]) {
                    images.push(this.$options.filters.cdnUrl(this.texts[`products_image${i}`]));
                }
            }
            return images;
        },

        lang() {
            return this.$store.getters['Language/current'];
        },

        slides() {
            const data = this.texts.slides || [];
            return data.map((slide) => ({
                image: this.$options.filters.cdnUrl(slide.image),
                title: slide[`title_${this.lang}`],
            }));
        },

        texts() {
            return this.$store.state.data;
        },

        settings() {
            return this.texts.settings?.[0] || {};
        },

        highlights() {
            return this.texts.highlights || [];
        },
    },

    methods: {
        onHorizontalImagesClick(index) {
            const imgs = [...this.showcaseImages];
            const reorderArray = (array, split) => {
                const lastPart = array.splice(0, split);
                const newArray = [...array, ...lastPart];
                return newArray;
            };
            const reorderedImages = index > 0 ? reorderArray(imgs, index) : imgs;
            if (reorderedImages.length) {
                this.$store.commit('updateOverlay', reorderedImages);
            }
        },
    },
};
</script>

<style>
.home__news>div:first-child {
    border-right: 0.2rem solid white;
    padding-right: var(--column-gap);
}

.home__news .highlight {
    margin-bottom: 0 !important;
}

.home__news {
    position: relative;
}

.more__news {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 48em) {
    .more__news {
        position: relative;
    }

    .home__news>div:first-child {
        border-right: 0;
        margin-bottom: var(--size-l);
    }
}

.home__products .columns {
    position: relative;
    --column-gap: var(--size-xxl);
}

.home__products .columns>div:not(:first-child) {
    position: relative;
}

.home__products .columns>div:not(:first-child)::before {
    content: '';
    width: 0.2rem;
    background: white;
    position: absolute;
    left: 0;
    top: 1rem;
    bottom: 0rem;
}
</style>
