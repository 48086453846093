var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pagebg"},[_c('Slider',{staticClass:"slider--gradient",attrs:{"ratio":"5by2","animation":"fade","slides":_vm.slides},scopedSlots:_vm._u([{key:"slide",fn:function({ slide }){return [_c('div',{staticClass:"c w-xxl flex hide__xs"},[_c('h1',{staticClass:"m-0 col-8 col-6__m-up col-4__l-up pt-l color-white"},[_vm._v(" "+_vm._s(slide.title)),_c('span',{staticClass:"color-accent"},[_vm._v(".")])])])]}}])}),_c('div',{staticClass:"c w-xxl"},[_c('div',{staticClass:"flex -justify-center centeredtext--home"},[_c('CenteredText',{attrs:{"title":_vm.texts['about_title_' + _vm.lang],"text":_vm.texts['about_text_' + _vm.lang],"link":{
                title: _vm.$t('about.more'),
                to: { name: 'about' },
                class: 'bg-white',
                icon: 'chevron-right'
            }}})],1)]),_c('div',{staticClass:"products__home section-margin-top"},[(_vm.showcaseImages.length > 0)?_c('HorizontalContentSlider',{attrs:{"items":_vm.showcaseImages},scopedSlots:_vm._u([{key:"item",fn:function({ item, index }){return [_c('img',{staticClass:"rounded cursor-pointer",attrs:{"src":item,"loading":"lazy"},on:{"click":function($event){return _vm.onHorizontalImagesClick(index)}}})]}}],null,false,1693645297)}):_vm._e(),_c('div',{staticClass:"c w-xxl home__products mt-xl"},[_c('div',{staticClass:"flex -wrap columns color-secondary -align-stretch"},[_c('div',{staticClass:"col-12 col-6__s-up col-4__l-up pb-0"},[_c('h2',{staticClass:"color-primary",domProps:{"textContent":_vm._s(_vm.texts['products_title_' + _vm.lang])}})]),_c('div',{staticClass:"col-12 col-6__s-up col-4__l-up pb-0"},[_c('div',{staticClass:"mt-r"},[_vm._v(" "+_vm._s(_vm.texts['products_text_' + _vm.lang])),_c('br'),_c('br'),_c('router-link',{staticClass:"bg-white -s button",attrs:{"to":{ name: 'products' }}},[_vm._v(_vm._s(_vm.$t('menu.products'))+" "),_c('icon',{staticClass:"mdi mdi-chevron-right"})],1)],1)]),(_vm.highlights.length)?_c('div',{staticClass:"col-12 col-6__s-up col-4__l-up pb-0"},[_c('Highlight',{attrs:{"title":_vm.highlights[0]['title_' + _vm.lang],"text":_vm.highlights[0]['text_' + _vm.lang]}})],1):_vm._e()])])],1),(_vm.texts.home_video)?_c('div',{staticClass:"video bg-white ratio-3by1 section-margin-top"},[_c('VideoBG',{attrs:{"src":_vm.texts.home_video}})],1):_vm._e(),_c('div',{staticClass:"c w-xxl pv-xxl"},[_c('div',{staticClass:"home__news flex columns mt-xxl pv-xxl -align-stretch -justify-center -wrap"},[_c('div',{staticClass:"col-12 col-3__l-up text-right flex -column -align-end pb-0"},[_c('h1',{staticClass:"mt-0",domProps:{"innerHTML":_vm._s(_vm.$t('news.title').split(' ').join('<br />'))}}),_c('span',{staticClass:"-flex-grow"}),(_vm.settings.enormail_id)?_c('Newsletter',{attrs:{"translations":{
                    email: _vm.$t('newsletter.email'),
                    subscribe: _vm.$t('newsletter.subscribe'),
                },"formId":_vm.settings.enormail_id,"buttonClass":"bg-white -icon -s","inputClass":"-s"},scopedSlots:_vm._u([{key:"default_header",fn:function(){return [_c('div',{staticClass:"text-uppercase weight-b color-secondary"},[_vm._v(" "+_vm._s(_vm.$t('newsletter.title'))+": ")])]},proxy:true},{key:"success",fn:function(){return [_c('div',{staticClass:"text-right color-success size-6"},[_vm._v(" "+_vm._s(_vm.$t('newsletter.success'))+" ")])]},proxy:true},{key:"buttonContent",fn:function(){return [_c('icon',{staticClass:"mdi mdi-chevron-right"})]},proxy:true}],null,false,3341231486)}):_vm._e()],1),_c('div',{staticClass:"col-12 col-7__l-up pb-0"},[_c('NewsArticles',{attrs:{"amount":2,"articleClass":"col-12 col-6__s-up mb-xxl mb-0__m-up pb-0"}})],1),_c('router-link',{staticClass:"more__news",attrs:{"to":{ name: 'news' }}},[_c('icon',{staticClass:"mdi mdi-chevron-right size-1"})],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }